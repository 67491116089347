import React from "react";
import styled from "@emotion/styled";
import {keyframes} from "@emotion/core";

import {Link} from "gatsby";
import colors from "../lib/colors";

const common = {
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  full: {position: "absolute", top: 0, left: 0, right: 0, bottom: 0},
  stretchCol: {
    display: "flex",
    flexDirection: "column",
    flex: "auto",
  },
};

const animations = {
  rotate: keyframes({
    "0%": {transform: "rotate(0deg)"},
    "100%": {transform: "rotate(360deg)"},
  }),
};

// eslint-disable-next-line jsx-a11y/anchor-has-content
const LinkOrA = props =>
  props.to ? <Link {...props} /> : <a target="_blank" rel="noopener noreferrer" {...props} />;

export default {
  common,
  animations,
  Row: styled("div")({display: "flex"}),
  Col: styled("div")({display: "flex", flexDirection: "column"}),
  H1: styled("h1")({
    color: colors.white,
    fontWeight: "bold",
    fontSize: "1.8rem",
    "@media(max-width: 500px)": {
      fontSize: "1.5rem",
    },
  }),
  H2: styled("h2")({
    fontWeight: "bold",
    fontSize: "1.3rem",
  }),
  Link: styled(LinkOrA)({
    fontWeight: "bold",
    color: colors.link,
    transitionProperty: "color",
    ":hover": {color: colors.linkHover},
  }),
};
