import React from "react";
import {css} from "@emotion/core";
import xcolors from "../../lib/xcolors";
import Col from "./Col";
import Ui from "../../components/Ui";

export const spinnerStyles = {
  container: css({
    zIndex: 1,
  }),
  g: css({
    animation: `${Ui.animations.rotate} 2s infinite linear`,
    transformOrigin: "50% 50%",
  }),
  cover: css({
    backgroundColor: "rgba(250,250,250,0.8)",
    zIndex: -1,
  }),
};

export const RawSpinner = React.forwardRef(({size = 16, style, ...rest}, ref) => (
  <svg
    style={{width: Math.min(150, size), height: Math.min(150, size), ...style}}
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    stroke={xcolors.gray300}
    {...rest}
    ref={ref}
  >
    <g css={spinnerStyles.g} strokeWidth="2" fill="none">
      <circle strokeOpacity=".25" cx="20" cy="20" r="18" />
      <path strokeOpacity=".5" d="M38 20A18 18 0 0 0 20 2" />
    </g>
  </svg>
));

export const Spinner = ({size = 75, withCover, coverColor, className, ...rest}) => (
  <Col
    absolute
    inset="full"
    css={spinnerStyles.container}
    {...rest}
    align="center"
    justify="center"
  >
    <RawSpinner size={size} />
    {withCover && (
      <Col
        css={spinnerStyles.cover}
        absolute
        inset="full"
        style={coverColor ? {backgroundColor: coverColor} : {}}
      />
    )}
  </Col>
);
