import React from "react";

const getDimensions = () => {
  if (typeof document !== "undefined") {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    };
  } else {
    return {width: 1024, height: 600};
  }
};

const useWindowSize = () => {
  const [size, setSize] = React.useState(getDimensions);
  React.useEffect(() => {
    const onResize = () => {
      setSize(getDimensions());
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);
  return size;
};

export default useWindowSize;
