export default {
  white: "#ffffff",

  gray950: "#161218",
  gray900: "#262832",
  gray800: "#333642",
  gray700: "#4C5060",
  gray600: "#707587",
  gray500: "#979EAF",
  gray400: "#BBC1CE",
  gray300: "#DADFE7",
  gray200: "#EBEEF3",
  gray100: "#F2F4F7",

  white_a05: "rgba(255,255,255, 0.05)",
  white_a10: "rgba(255,255,255, 0.10)",
  white_a25: "rgba(255,255,255, 0.25)",

  black_a05: "rgba(0,0,0, 0.05)",
  black_a10: "rgba(0,0,0, 0.10)",
  black_a25: "rgba(0,0,0, 0.25)",

  accent: "#e50f3c", // 500
  accent_a05: "rgba(229, 15, 60, 0.05)",
  accent_a10: "rgba(229, 15, 60, 0.10)",
  accent_a25: "rgba(229, 15, 60, 0.25)",
  accent_a50: "rgba(229, 15, 60, 0.5)",

  accent900: "#210007",
  accent800: "#5D0215",
  accent700: "#950523",
  accent600: "#C10930",
  accent500: "#E50F3C",
  accent400: "#F62344",
  accent300: "#FC4E61",
  accent200: "#FE8B91",
  accent100: "#FFDDDD",

  active: "#30A783",
  active_l1: "#45BA96",
  active_l2: "#65D1B0",
  active_l3: "#8CE6CA",
  active_l4: "#BAF6E3",
  active_l5: "#E8FFF8",

  active_a25: "rgba(48, 167, 131, 0.25)",
  active_a50: "rgba(48, 167, 131, 0.50)",

  comment_d3: "#475162",
  comment_d2: "#606e85",
  comment: "#d4dbe7",
  comment_l1: "#e4e7ec",

  blue900: "#010C1B",
  blue800: "#042A5D",
  blue700: "#09418E",
  blue600: "#1158BA",
  blue500: "#1E6BD6",
  blue400: "#3A89EB",
  blue300: "#61A7F6",
  blue200: "#98CAFC",
  blue100: "#E8F4FF",

  link: "#6DAFC5",

  done: "#31d367", // 400

  done900: "#021F0C",
  done800: "#15873B",
  done700: "#28B758",
  done600: "#31D367",
  done500: "#47EA7E",
  done400: "#66F194",
  done300: "#90F3B1",
  done200: "#BBF7CF",
  done100: "#ECFBF1",

  started: "#2569AD", // 400

  started900: "#02172D",
  started800: "#07325D",
  started700: "#134C86",
  started600: "#2569AD",
  started500: "#3A81C7",
  started400: "#5597D9",
  started300: "#7DB2E7",
  started200: "#B1D4F6",
  started100: "#E8F3FD",

  deleted: "#43282A", // 400

  deleted900: "#0F0506",
  deleted800: "#2A1214",
  deleted700: "#391D1F",
  deleted600: "#43282A",
  deleted500: "#543E3F",
  deleted400: "#746263",
  deleted300: "#9D8F90",
  deleted200: "#CABCBD",
  deleted100: "#FDF0F1",

  archived: "#291600", // 200

  archived900: "#0B0600",
  archived800: "#291600",
  archived700: "#362512",
  archived600: "#4E3F2C",
  archived500: "#746859",
  archived400: "#9A9084",
  archived300: "#BDB6AD",
  archived200: "#DBD6CF",
  archived100: "#EFEBE7",

  review: "#159590", // 400

  review900: "#011E1D",
  review800: "#064D4A",
  review700: "#0D726E",
  review600: "#159590",
  review500: "#24ACA6",
  review400: "#46BDB9",
  review300: "#76CCC9",
  review200: "#A9DFDD",
  review100: "#E6F6F6",

  assigned: "#a8925d", // 500

  assigned900: "#1D1708",
  assigned800: "#584821",
  assigned700: "#86703B",
  assigned600: "#9A834D",
  assigned500: "#A8925D",
  assigned400: "#B8A473",
  assigned300: "#C9B890",
  assigned200: "#DACFB3",
  assigned100: "#F4EFE4",

  blocked: "#c01b1b", // 400

  blocked900: "#330202",
  blocked800: "#600808",
  blocked700: "#900F0F",
  blocked600: "#C01B1B",
  blocked500: "#D83333",
  blocked400: "#E56767",
  blocked300: "#ED9595",
  blocked200: "#F2BEBE",
  blocked100: "#F8DBDB",

  snoozing: "#7d1274", // 400

  snoozing900: "#290226",
  snoozing800: "#470642",
  snoozing700: "#630B5C",
  snoozing600: "#7D1274",
  snoozing500: "#98208E",
  snoozing400: "#B540AB",
  snoozing300: "#D772CF",
  snoozing200: "#EFA7E9",
  snoozing100: "#F9CFF6",

  warn900: "#431901",
  warn800: "#7E3303",
  warn700: "#A94B05",
  warn600: "#CD6108",
  warn500: "#EF910F",
  warn400: "#FBBA20",
  warn300: "#FFCC40",
  warn200: "#FFD673",
  warn100: "#FFECC0",

  purple900: "#120326",
  purple800: "#230947",
  purple700: "#3A176A",
  purple600: "#57318C",
  purple500: "#7D5DA9",
  purple400: "#A48BC6",
  purple300: "#BDABD5",
  purple200: "#D8CEE5",
  purple100: "#E8E5EC",

  active900: "#05241A",
  active800: "#0F5F47",
  active700: "#1E8C6B",
  active600: "#30A783",
  active500: "#47BE9A",
  active400: "#65D1B0",
  active300: "#8CE6CA",
  active200: "#BAF6E3",
  active100: "#F3FDFA",

  bgPurple: "#381B49",
  purpleDim: "#47425D",
  purpleDarkDim: "#1c172f",

  warn_a25: "rgba(239, 145, 15, 0.25)",

  drag: "rgba(60,89,148,0.95)",

  danger: "#9b3212",
  danger_l1: "#c44823",
  danger_l5: "#FAE4DE",

  error900: "#230205",
  error800: "#610910",
  error700: "#8B121C",
  error600: "#AF222D",
  error500: "#BF3541",
  error400: "#D16069",
  error300: "#DA9197",
  error200: "#E7C0C3",
  error100: "#F2E6E7",
};
