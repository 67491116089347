import React from "react";
import CardFall from "./CardFall";
import {Box, XCol} from "./xui/Box";
import {themePurple} from "../page-styles/landingpage.css";
import {ThemedButton} from "./xui/ThemedButton";

export const CTAContainer = ({children, ...rest}) => (
  <Box
    as={CardFall}
    bg="bgBack"
    cardPer100px={2}
    borderBottomWidth={1}
    borderColor="purple700"
    className={themePurple}
    px={5}
    sm_px={3}
  >
    <XCol sp={4} py={9} align="center" width="100%" maxWidth="lg" ml="auto" mr="auto" {...rest}>
      {children}
    </XCol>
  </Box>
);

export const ExplainCodecks = () => (
  <CTAContainer>
    <Box as="h3" color="primary" size="xxl">
      So, what is Codecks?
    </Box>
    <Box color="body" size="md">
      Codecks is a project management tool inspired by collectible card games.
    </Box>
    <ThemedButton kind="primary" to="/">
      Learn more
    </ThemedButton>
  </CTAContainer>
);

export const TryCodecks = () => (
  <CTAContainer>
    <Box color="primary" size="xxl" bold>
      Join 25,000+ teams!
    </Box>
    <Box color="body" size="md">
      Free for up to 5 users
    </Box>
    <ThemedButton kind="primary" to="/create-account/">
      Get started with Codecks
    </ThemedButton>
  </CTAContainer>
);
