import React from "react";
import css from "@emotion/css";
import {classes, containerPropsToCss, spacingSteps, addClasses} from "./classes";
import {pushClass} from "./Push";

const rowClass = css({display: "flex", flexDirection: "row"});

addClasses(["vertSpacing"], spacingSteps, val => ({
  [`> *:not(:last-child):not(.css-${pushClass.name})`]: {marginRight: `${val}rem`},
}));

const rowCss = props => {
  const {css: passedCss, sp, align, justify, breakAt, ...rest} = props;
  const cssList = passedCss ? [passedCss] : [];
  cssList.push(rowClass);
  if (sp !== undefined && sp !== null) cssList.push(classes.vertSpacing[sp]);
  if (align) cssList.push(classes.alignItems[align]);
  if (justify) cssList.push(classes.justifyContent[justify]);
  if (breakAt) {
    const qConfig = typeof breakAt === "string" ? {query: breakAt} : breakAt;
    const qSp = qConfig.sp || sp;
    cssList.push(
      css({
        [qConfig.query]: {
          flexDirection: "column",
          ...(qConfig.align && {alignItems: qConfig.align}),
          [`> *:not(:last-child):not(.css-${pushClass.name})`]: {
            marginRight: 0,
            ...(qSp !== null && qSp !== undefined && {marginBottom: `${spacingSteps[qSp]}rem`}),
          },
        },
      })
    );
  }

  return {css: cssList, ...rest};
};

const Row = React.forwardRef((props, ref) => {
  const Comp = props.as || "div";
  return <Comp ref={ref} {...containerPropsToCss(rowCss(props))} />;
});

export default Row;
