import React from "react";
import {Text} from "../../components/ui/Text";
import styled from "@emotion/styled";
import colors from "../colors";
import Col from "../../components/ui/Col";
import Row from "../../components/ui/Row";
import {RawSpinner} from "../../components/ui/Spinner";
import {SpawnAnchoredOverlay, ArrowOverlay} from "../../components/Overlay";
import xcolors from "../xcolors";
import {css} from "@emotion/core";

const Label = styled("label")(({showingErrors}) => ({
  position: "relative",
  display: "block",
  alignSelf: "start",
  cursor: "pointer",
  marginBottom: "0.4rem",
  fontSize: "0.8rem",
  fontWeight: "bold",
  color: showingErrors ? colors.dangerTextOnDark : colors.white,
}));

const containerShadow = ({hasError, hasFocus, light}) =>
  [
    `0 0 0 2px ${hasFocus ? xcolors.blue300 : hasError ? xcolors.error500 : "transparent"}`,
    light ? "0 0.3rem 0.7rem rgba(0,0,0,0.05)" : "0 0.3rem 0.7rem rgba(0,0,0,0.35)",
    light ? "0 1px 0.2rem rgba(0,0,0,0.1) inset" : "0 1px 0.2rem rgba(0,0,0,0.2) inset",
  ].join(",");

const styles = {
  container: {
    default: css({
      width: "100%",
      backgroundColor: xcolors.gray200,
      transitionProperty: "opacity, box-shadow, background-color",
      boxShadow: containerShadow({}),
      padding: "0.3rem 0.5rem",
      borderRadius: "9999px",
    }),
    light: css({
      backgroundColor: xcolors.white,
      border: `1px solid ${xcolors.gray300}`,
      boxShadow: containerShadow({light: true}),
    }),
    focussed: css({
      backgroundColor: xcolors.white,
      boxShadow: containerShadow({hasFocus: true}),
    }),
    withErrors: css({
      backgroundColor: xcolors.error100,
      boxShadow: containerShadow({hasError: true}),
    }),
    disabled: css({
      opacity: 0.5,
    }),
  },
};

export const SimpleWithLabel = ({label, className, htmlFor, error, style, children}) => (
  <Col sp={2} className={className} style={style}>
    <Label htmlFor={htmlFor}>{label}</Label>
    <Col sp={1}>
      {children}
      {error && (
        <Text size={1} color="error600" preset="bold">
          {error}
        </Text>
      )}
    </Col>
  </Col>
);

export const WithLabel = ({
  children,
  className,
  style,
  label,
  name,
  hint,
  hasPendingValidation,
  showingErrors,
}) => (
  <Col sp={2} className={className} style={{...style, maxWidth: "100%"}}>
    {label && (
      <Label htmlFor={name} showingErrors={showingErrors}>
        {label}
        {hasPendingValidation && (
          <Row inset="y" absolute align="center" pl={2} style={{left: "100%"}}>
            <RawSpinner />
          </Row>
        )}
      </Label>
    )}
    <Col sp={1}>
      {children}
      {hint && (
        <Text preset="bold" color="gray500" size={1}>
          {hint}
        </Text>
      )}
    </Col>
  </Col>
);

const useFocussedState = ({onBlur: onParentBlur, onFocus: onParentFocus}) => {
  const [isFocussed, setIsFocussed] = React.useState(false);
  return [
    isFocussed,
    React.useCallback(
      e => {
        if (onParentFocus) onParentFocus(e);
        setIsFocussed(true);
      },
      [onParentFocus]
    ),
    React.useCallback(
      e => {
        if (onParentBlur) onParentBlur(e);
        setIsFocussed(false);
      },
      [onParentBlur]
    ),
  ];
};

const renderErrors = ({errors, ...rest}) => (
  <ArrowOverlay
    background={xcolors.error700}
    color={xcolors.white}
    contentCss={{width: "15rem", padding: "1rem"}}
    {...rest}
  >
    <Col sp={2}>
      {errors.map((e, idx) => (
        <Text color="white" key={idx}>
          {e}
        </Text>
      ))}
    </Col>
  </ArrowOverlay>
);

export const ErrorHint = ({errors, forceShow}) => {
  const [isHover, setIsHover] = React.useState(false);
  if (errors.length === 0) return null;
  return (
    <SpawnAnchoredOverlay
      distanceFromAnchor={15}
      placement="top"
      isOpen={forceShow || isHover}
      renderOverlay={p => renderErrors({errors, ...p})}
    >
      {measureRef => (
        <Text
          color="danger"
          preset="bold"
          ref={measureRef}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          !
        </Text>
      )}
    </SpawnAnchoredOverlay>
  );
};

export const FieldWithLabel = React.forwardRef((props, ref) => {
  const {
    as: Comp,
    name,
    label = name,
    errors = [],
    prefix,
    postfix,
    onFocus,
    onBlur,
    onChange,
    hint,
    className,
    hasPendingValidation,
    style,
    fieldStyle,
    onLight,
    disabled,
    align = "baseline",
    ...rest
  } = props;
  const hasErrors = errors.length > 0;
  const [isFocussed, onWrappedFocus, onWrappedBlur] = useFocussedState({onFocus, onBlur});
  const [showError, setShowError] = React.useState(false);

  React.useEffect(() => {
    if (hasErrors && isFocussed) setShowError(true);
  }, [hasErrors, isFocussed]);

  const handleFocus = e => {
    onWrappedFocus(e);
    setShowError(true);
  };

  const handleBlur = e => {
    onWrappedBlur(e);
    setShowError(false);
  };

  const innerRef = React.useRef();
  const actualRef = ref || innerRef;

  const handleContainerClick = () => {
    if (actualRef.current && actualRef.current.focus) {
      actualRef.current.focus();
    }
  };

  const handleChange = e => {
    if (onChange) onChange(e);
    setShowError(false);
  };

  return (
    <WithLabel
      label={label}
      name={label}
      hint={hint}
      className={className}
      style={style}
      hasPendingValidation={hasPendingValidation}
      showingErrors={hasErrors}
    >
      <Row
        sp={2}
        align={align}
        css={[
          styles.container.default,
          onLight && styles.container.light,
          errors.length > 0 && styles.container.withErrors,
          isFocussed && styles.container.focussed,
          disabled && styles.container.disabled,
        ]}
        onClick={handleContainerClick}
      >
        {prefix}
        <Comp
          id={name}
          name={name}
          ref={ref}
          style={fieldStyle}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
          {...rest}
        />
        <ErrorHint errors={errors} forceShow={showError} />
        {postfix}
      </Row>
    </WithLabel>
  );
});
