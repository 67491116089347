import {forwardRef} from "react";
import {uiStyles} from "./xui.css";

type Styles = typeof uiStyles;
type Booleanify<E> = E extends "true" ? true : E;
type BoxProps = {[Key in keyof Styles]?: Booleanify<keyof Styles[Key]>} & {
  className?: string;
  children?: React.ReactNode;
  as?: any;
};

export const Box = forwardRef((props: BoxProps, ref) => {
  let Comp = "div";
  const forwardProps = {ref} as any;
  const classNames = [];
  for (const prop in props) {
    const val = props[prop];
    if (val === null || val === undefined) continue;
    switch (prop) {
      case "as":
        Comp = val;
        break;
      case "children":
        forwardProps[prop] = val;
        break;
      case "className":
        classNames.push(val);
        break;
      case "forwardProps":
        if (val.className) classNames.push(val.className);
        Object.assign(forwardProps, val);
        break;
      default:
        const s = uiStyles[prop];
        if (!s) {
          forwardProps[prop] = val;
        } else {
          if (val === false) continue;
          const className = s[val];
          if (!className) throw new Error(`Unknown style '${prop}=${val}'`);
          classNames.push(className);
        }
    }
  }
  forwardProps.className = classNames.join(" ");

  return <Comp {...forwardProps} />;
});

export const XCol = (props: BoxProps) => <Box display="flex" flexColumn="column" {...props} />;
export const XRow = forwardRef((props: BoxProps, ref) => (
  <Box display="flex" flexColumn="row" {...props} ref={ref} />
));
export const XPush = props => <Box fillParent {...props} />;
export const XStrip = forwardRef(
  (
    {
      bg,
      outerChild,
      width,
      fillParent,
      className,
      noOverflow,
      id,
      ...props
    }: BoxProps & {outerChild?: React.ReactNode; width: BoxProps["maxWidth"]; id?: string},
    ref
  ) => (
    <Box
      as="section"
      display="flex"
      flexColumn="column"
      align="center"
      bg={bg}
      px={5}
      sm_px={3}
      relative
      fillParent={fillParent}
      className={className}
      ref={ref}
      noOverflow={noOverflow}
      id={id}
    >
      <XCol width="100%" maxWidth={width} py={5} fillParent={fillParent} {...props} />
      {outerChild}
    </Box>
  )
);
