export const trackEvent = ({category, label, action, cb}) => {
  if (window && window.gtag) {
    let isSent = false;
    if (cb) {
      setTimeout(() => {
        if (!isSent) {
          isSent = true;
          cb();
        }
      }, 250);
    }
    window.gtag(`event`, action, {
      event_category: category,
      event_label: label,
      transport_type: cb ? `beacon` : ``,
      event_callback: () => {
        if (isSent) return;
        isSent = true;
        if (cb) cb();
      },
    });
  } else {
    if (cb) cb();
  }
};

export const trackPageview = path => {
  if (window && window.gtag) {
    window.gtag("event", "page_view", {
      page_path: path,
    });
  }
};
