import React from "react";
import {useCodeInfoState} from "./CodeInfoProvider";
import Col from "../../components/ui/Col";
import {Text} from "../../components/ui/Text";
import css from "@emotion/css";

const brify = text => {
  const list = [];
  const splitted = text.trim().split("\n");
  splitted.forEach((text, i) => {
    list.push(text);
    if (i < splitted.length - 1) list.push(<br />);
  });
  return React.createElement(React.Fragment, {}, ...list);
};

const styles = {
  outer: css({boxShadow: `0 -2rem 1rem -2rem rgba(0,0,0,0.4) inset`}),
};

const RenderGiftBonus = ({amountInCents}) => (
  <div>
    <Text size={1} color="purple200" as="span">
      This code will give you a{" "}
    </Text>
    <Text preset="bold" size={2} color="white" as="span">
      €{(amountInCents / 100).toFixed(2)}
    </Text>
    <Text size={1} color="purple200" as="span">
      {" "}
      bonus when creating your account!
    </Text>
  </div>
);
const RenderDiscountBonus = ({percentage, durationInMonths}) => (
  <div>
    <Text size={1} color="purple200" as="span">
      This code will give you{" "}
    </Text>
    <Text preset="bold" size={2} color="white" as="span">
      {percentage}%
    </Text>
    <Text size={1} color="purple200" as="span">
      {" "}
      off for any subscription
      {durationInMonths > 0
        ? ` in the first ${durationInMonths} month${durationInMonths === 1 ? "" : "s"}`
        : ""}
      .
    </Text>
  </div>
);

const CodeInfoHint = ({fallback}) => {
  const {codeInfo, hideHint} = useCodeInfoState();
  if (!codeInfo || hideHint) return fallback || null;
  return (
    <Col bg="purple500" align="center" px={3} py={3} css={styles.outer}>
      <Col css={{maxWidth: "35rem"}} sp={2}>
        {codeInfo.message && (
          <Text color="white" preset="bold" size={2}>
            {brify(codeInfo.message)}
          </Text>
        )}
        {codeInfo.ok && codeInfo.reward ? (
          codeInfo.reward.type === "gift" ? (
            <RenderGiftBonus {...codeInfo.reward.payload} />
          ) : (
            <RenderDiscountBonus {...codeInfo.reward.payload} />
          )
        ) : (
          <div>
            <Text size={1} color="purple200" as="span">
              There's been an issue with this referral code:{" "}
            </Text>
            <Text size={1} color="white" preset="bold" as="span">
              {codeInfo.error}
            </Text>
          </div>
        )}
      </Col>
    </Col>
  );
};

export default CodeInfoHint;
