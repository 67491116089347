import React, {useEffect, useState} from "react";
import {Link} from "gatsby";
import {useAppInfo} from "../features/AppInfo";
import {Box, XCol, XRow} from "./xui/Box";
import cx from "./xui/cx";
import {uiStyles} from "./xui/xui.css";
import {ThemedButton} from "./xui/ThemedButton";
import MiniEvent from "../lib/mini-event";
import {createObserver} from "./ColorScroller";

const ActiveAwareLink = ({className, exact, ...p}) => {
  if (p.href) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a className={cx(className, uiStyles.color.body)} {...p} />;
  } else {
    return (
      <Link
        getProps={({isCurrent, isPartiallyCurrent}) => ({
          className: cx(
            className,
            ...((exact && isCurrent) || (!exact && isPartiallyCurrent)
              ? [uiStyles.color.primary, uiStyles.navLinkActive.true]
              : [uiStyles.color.body])
          ),
        })}
        {...p}
      />
    );
  }
};

const NavLink = ({hideAt, ...props}) => {
  const passProps = {...props, ...(hideAt && {[`${hideAt}_display`]: "none"})};
  return (
    <Box
      as={ActiveAwareLink}
      {...passProps}
      hover_color="primary"
      weight="bold"
      pa={5}
      md_pa={4}
      sm_px={2}
      size="lg"
    />
  );
};

const AuthRow = ({children}) => (
  <XRow sp={3} align="center" noShrink>
    {children}
  </XRow>
);

const AuthSection = ({appInfo}) => {
  if (appInfo.accounts && appInfo.accounts.length > 0) {
    const account = appInfo.accounts[0];
    return (
      <AuthRow>
        <ThemedButton
          kind="primary"
          size="sm"
          key={account.name}
          href={`https://${account.subdomain}${process.env.GATSBY_COOKIE_DOMAIN}`}
        >
          <Box as="span" sm_display="none">
            Go to {account.subdomain}
            {process.env.GATSBY_COOKIE_DOMAIN}
          </Box>
          <Box as="span" sm_display="block" display="none">
            Open App
          </Box>
        </ThemedButton>
        {appInfo.accounts.length > 1 && (
          <ThemedButton to="/login/" size="sm" className={uiStyles.sm_display.none}>
            see others
          </ThemedButton>
        )}
      </AuthRow>
    );
  } else if (appInfo.steamyGuilds && appInfo.steamyGuilds.length) {
    return (
      <AuthRow>
        <ThemedButton className={uiStyles.md_display.none} size="sm" to="/create-account/">
          Create account
        </ThemedButton>
        <ThemedButton
          kind="primary"
          size="sm"
          href={`https://steamy${process.env.GATSBY_COOKIE_DOMAIN}`}
        >
          Open Steamy
        </ThemedButton>
      </AuthRow>
    );
  } else {
    return (
      <AuthRow>
        <ThemedButton size="sm" to="/login/">
          Log in
        </ThemedButton>
        <ThemedButton
          kind="primary"
          size="sm"
          className={uiStyles.md_display.none}
          to="/create-account/"
        >
          Create your account
        </ThemedButton>
      </AuthRow>
    );
  }
};

const CdxLogo = ({className}) => (
  <svg viewBox="0 0 1390 411" fill="none" className={className}>
    <Box
      as="path"
      d="M507.6 346.2c25.7 0 51.6-16.2 57.7-51.2h-30.4C529 313.3 517 319 507.6 319c-19 0-31.2-18-31.2-41.6 0-23.7 12.2-41.7 31.2-41.7 9.5 0 21.4 5.6 27.3 23.9h30.4c-6.1-35-31.8-51.2-57.7-51.2-37.8 0-62.4 29.2-62.4 69 0 39.7 24.6 69 62.4 69zM648 346.2c38 0 62.4-29.2 62.4-69 0-40-24.4-69-62.5-69-37.8 0-62.4 29.3-62.4 69 0 39.8 24.6 69 62.4 69zm0-29.2c-18 0-31.3-13.2-31.3-39.7 0-26.6 13.3-39.8 31.2-39.8 18.6 0 31.2 13.2 31.2 39.8 0 26.5-12.6 39.7-31.2 39.7zM784.3 346.2c13.8 0 26-5 38.3-18.3l4.3 15.7H850V157.9H819v68.7a47.4 47.4 0 0 0-36.5-18.3c-29.6 0-51.3 29.2-51.3 69 0 39.7 21.7 69 53.2 69zm7.4-30.7c-23.8 0-29.4-26-29.4-38.2 0-12.2 5.6-38.2 29.4-38.2 11.1 0 27.2 9.2 27.2 29.1v18a28.3 28.3 0 0 1-27.2 29.3zM935.2 346.2c23.3 0 48.4-10.3 55.5-44.8h-28.5c-3 10.9-12.2 19.1-27.5 19.1-18.3 0-31-15.1-31-33.2h56.1c20.4 0 33.6-6.9 33.6-25.7a56.8 56.8 0 0 0-58.2-53.3c-40 0-61.9 31.3-61.9 69.8 0 38.2 23.8 68.1 61.9 68.1zm11.6-78h-43c0-17.7 10.5-34.5 31.4-34.5 15.6 0 27 11.5 27 24.2 0 4.2-3.7 10.3-15.4 10.3zM1071 346.2c25.7 0 51.7-16.2 57.7-51.2h-30.4c-5.8 18.3-17.7 23.9-27.2 23.9-19 0-31.2-18-31.2-41.6 0-23.7 12.1-41.7 31.2-41.7 9.5 0 21.4 5.6 27.2 23.9h30.4c-6-35-31.7-51.2-57.6-51.2-37.8 0-62.4 29.2-62.4 69 0 39.7 24.6 69 62.4 69zM1232.1 343.6h35.2l-49.5-81.7 48.2-51h-37.3l-45.8 47.8V157.9h-31.2v185.7h31.2v-44l14.8-15 34.4 59zM1331.3 346.2c33.8 0 58.7-15 58.7-42.2 0-23.6-15.3-31.5-41-36.3l-20.3-3.4c-12.2-2.2-22.8-4-22.8-14.4 0-9.8 11.4-16.2 24.9-16.2 16 0 26.4 8 26.4 20.7h27.5c0-28-19.8-46.1-54-46.1-28.8 0-54.2 14.3-54.2 40.3 0 24.1 19.3 32.9 40.2 36.3l23.3 4c17.2 3 20.6 8 20.6 14.6 0 11.2-13 17.3-29.3 17.3-18.3 0-29.6-9-29.6-24h-27.5c0 28.5 20.6 49.4 57 49.4z"
      svgFill="primary"
    />
    <path
      d="M128.6 266.6l167.2 20.6c-11.3 45.8-64.7 117.5-150.6 115.1-28-.7-67.2-13.4-98.2-40.2-33-36-35-46.2-42.4-87.9-5.9-83 47.8-160.1 130.6-166.6A152 152 0 0 1 265 159.3L128.6 266.6z"
      fill="#7BC0D6"
    />
    <path
      d="M149.3 257.5L278 162.7a201.1 201.1 0 0 0-43.8-40.5 141.6 141.6 0 0 0-72.9-22.6c-83.8-3.4-136.7 53-155 113.3-19 62.3 3.7 145 79 180.6 12 5.7 34.6 16.1 72.2 14.3a148.6 148.6 0 0 0 69.2-20.4c15.5-8.7 34.2-24 47-44.6 8.1-13.4 15.4-24 29.2-60.7l-153.7-24.6zm-84-69.3A103 103 0 0 0 41.1 268a105 105 0 0 0 73.9 89c19.8 6.9 54 7.2 84.6-7a105.4 105.4 0 0 0 54.6-65.2l33.7 4.6a176 176 0 0 1-21.2 46.1 131.8 131.8 0 0 1-69 54c-31.1 11.2-69.1 13.7-99.4 2C31.8 366-6.4 297.8 9.3 224.2a139 139 0 0 1 72-94.1c58.9-32.2 130.6-26 177.5 30.7-12.5 10-12.2 9.8-29.6 24a118.2 118.2 0 0 0-37-27c-53.8-23.6-103.6 3.7-126.9 30.4z"
      fill="#34353D"
    />
    <path
      d="M245 289.5L121.8 265l87.5-73c-34.3-41.5-102.5-34.5-138 7.7A96.9 96.9 0 0 0 77.8 326c16 16 44.2 27.5 80.7 25.1 38.5-2.9 70.4-26.6 86.4-61.7z"
      fill="#E50D3B"
    />
    <path
      d="M145.5 355.6a98.2 98.2 0 0 1-71.2-30.2 96.3 96.3 0 0 1-6.8-128.7c20-23.5 49.3-37 80-37.2h.6a88.6 88.6 0 0 1 69 29.3l3 3.3-91.5 69.5 118.6 22.1-1.3 4.3-1 3a99.8 99.8 0 0 1-86.2 64.2l-13.2.4zm2-186.5A94 94 0 0 0 76 203c-31.4 38.8-19.9 89.3 6.4 115.7a91.2 91.2 0 0 0 72.6 27c34.6-2.7 59.5-21.8 76-52.4l-126-24.8 100-76.9a79.4 79.4 0 0 0-56.7-22.4h-.8zM282.8 232.6L334 109.1l79.5 41-50.1 122.4-80.6-39.9z"
      fill="#fff"
    />
    <path
      d="M335.6 104.8l-1-.4a3.4 3.4 0 0 0-4.6 1.8l-51.5 110.2c-2.7 5-4.8 10.9-7.5 16-1.5 3.2-.1 7 3 8.5 0 0 50 22.5 75.9 32.5 8.2 3.2 13.2 5.5 15.5 4.3 2.4-1.2 3.4-3.8 7.2-12.5 14.7-33 55.6-115.5 55.6-115.5a7 7 0 0 0-3.6-9.9l-89-35zm-1.6 5.1l78.1 40.4-49.6 120.6-77.2-39.1L334 109.9z"
      fill="#34353D"
    />
    <path d="M346.7 75.6l19.2-47.9 28 16.6-17.1 47-30.1-15.7z" fill="#fff" />
    <path
      d="M365.8 23.6l-.4-.2a1.4 1.4 0 0 0-2 .8l-21.7 46.6c-1.1 2.1-2 4.6-3.2 6.7-.6 1.4 0 3 1.3 3.6 0 0 21.2 9.6 32 13.8 3.6 1.3 5.7 2.3 6.7 1.8 1-.5 1.4-1.6 3-5.3 6.2-14 23.5-48.8 23.5-48.8a3 3 0 0 0-1.5-4.2l-37.7-14.8zm1 6.2l26.3 14.7L376 90l-27.7-15.2 18.4-45z"
      fill="#34353D"
    />
    <path d="M242.3 87.9l34.3-80 50 27.5-33.4 77.4-51-25z" fill="#fff" />
    <path
      d="M276 2.2l-.6-.3a2.3 2.3 0 0 0-3.2 1.3L236.8 79c-2 3.5-3.3 7.5-5.2 11-1 2.2-.1 4.8 2 5.9 0 0 34.5 15.5 52.3 22.4 5.7 2.2 9.1 3.8 10.7 3 1.6-.9 2.4-2.6 5-8.6C311.7 90 340 33 340 33a4.8 4.8 0 0 0-2.5-6.7L276.1 2.2zm.6 7.1l49 25.3-32.8 78.2-48.8-25 32.6-78.5z"
      fill="#34353D"
    />
  </svg>
);

export const HeaderLogo = ({noLink}) => {
  const content = (
    <>
      <Box as={CdxLogo} height="2.5" md_display="none" />
      <Box
        as="img"
        height="2"
        width="auto"
        display="none"
        md_display="block"
        forwardProps={{width: 900, height: 850}}
        src={require("../imgs/codecks-icon.svg")}
      />
    </>
  );
  return noLink ? <div>{content}</div> : <Link to="/">{content}</Link>;
};

export const headerThemeEvents = new MiniEvent();

export const HeaderThemeSentinel = ({theme}) => {
  const [bottomNode, setBottomNode] = useState();
  const [topNode, setTopNode] = useState();

  useEffect(() => {
    if (bottomNode && topNode) {
      const handleIntersect = () => headerThemeEvents.emit(theme);
      const unsubs = [
        createObserver({node: topNode, position: "top", onIntersect: handleIntersect}),
        createObserver({node: bottomNode, position: "bottom", onIntersect: handleIntersect}),
      ];
      return () => unsubs.forEach(fn => fn());
    }
  }, [topNode, bottomNode, theme]);

  return (
    <>
      <Box
        height="90vh"
        absolute
        top="0"
        left="0"
        right="0"
        pointerEvents="none"
        ref={setTopNode}
      />
      <Box
        height="5"
        absolute
        bottom="0"
        left="0"
        right="0"
        pointerEvents="none"
        ref={setBottomNode}
      />
    </>
  );
};

const Outer = ({isScrolled, nonFloating, children}) => {
  const [theme, setTheme] = useState(null);
  useEffect(() => headerThemeEvents.addListener(setTheme), []);

  const shared = {
    bg: !isScrolled && !nonFloating ? "transparent" : "bgBack",
    px: 6,
    md_px: 4,
    sm_px: 3,
    elevation: isScrolled ? "lg" : null,
    align: "center",
    className: theme,
  };

  return nonFloating ? (
    <XCol {...shared} position="sticky" top={0} zIndex={6}>
      {children}
    </XCol>
  ) : (
    <Box position="sticky" top={0} zIndex={6}>
      <XCol position="absolute" top={0} left={0} right={0} {...shared}>
        {children}
      </XCol>
    </Box>
  );
};

const Header = ({nonFloating}) => {
  const appInfo = useAppInfo();
  const [isScrolled, setScrolled] = React.useState(false);
  React.useEffect(() => {
    const onUpdate = () => setScrolled(window.scrollY > 50);
    onUpdate();
    window.addEventListener("scroll", onUpdate);
    return () => window.removeEventListener("scroll", onUpdate);
  }, []);
  return (
    <Outer isScrolled={isScrolled} nonFloating={nonFloating}>
      <XRow align="center" width="100%" maxWidth="xxxl" sp={2}>
        <HeaderLogo />
        <XRow ml="auto" mr="auto">
          <NavLink to="/features/">Features</NavLink>
          <NavLink href={`https://manual${process.env.GATSBY_COOKIE_DOMAIN}`} hideAt="lg">
            Manual
          </NavLink>
          <NavLink exact to="/showcase/" hideAt="lg">
            Showcase
          </NavLink>
          <NavLink to="/pricing/">Pricing</NavLink>
          <NavLink to="/changelog/" hideAt="lg">
            Changelog
          </NavLink>
          {/* <NavLink to="/blog/" hideAt="xl">
            Blog
          </NavLink> */}
        </XRow>
        <AuthSection appInfo={appInfo} />
      </XRow>
    </Outer>
  );
};

export default Header;
