import React from "react";
import {memo, useState, useRef, useEffect} from "react";
import cx from "./xui/cx";
import useWindowSize from "../lib/useWindowSize";
import {cardFallStyles as styles, ROTATION_DURATION} from "./CardFall.css";
import {uiStyles} from "./xui/xui.css";
import {Box} from "./xui/Box";

const Card = memo(({idx, height}) => {
  const scale = Math.random() * 1.25 + 0.5;
  const duration = (height * 0.2) / scale ** 1.5;
  return (
    <div
      className={styles.cardLane}
      style={{
        left: `${Math.random() * 100 - 3}%`,
        animationDuration: `${duration}s`,
        animationDelay: `-${duration * Math.random()}s`,
      }}
    >
      <div className={styles.cardContainer} style={{transform: `scale(${scale})`}}>
        <div
          className={cx(styles.card, styles.rotations[idx % styles.rotations.length])}
          style={{animationDelay: `-${ROTATION_DURATION * Math.random()}s`}}
        />
      </div>
    </div>
  );
});

export const InnerCardFall = ({cardPer100px = 3, className, fadeOut, fadeIn}) => {
  const [containerHeight, setContainerHeight] = useState(null);
  const containerRef = useRef();
  const {width} = useWindowSize();
  useEffect(() => {
    setContainerHeight(containerRef.current.clientHeight);
  }, [setContainerHeight]);
  const roundedHeight = containerHeight && Math.ceil(Math.max(1, containerHeight / 100)) * 100;
  const cardCount = Math.ceil(width / 100) * cardPer100px;
  return (
    <div className={cx(styles.fallCardsContainer, className)} ref={containerRef}>
      {roundedHeight
        ? Array.from(new Array(cardCount), (_, i) => (
            <Card key={i} idx={i} height={roundedHeight} />
          ))
        : null}
      {fadeIn && <Box absolute top={0} left={0} right={0} height={8} bg="fadeFromBg" />}
      {fadeOut && <Box absolute bottom={0} left={0} right={0} height={8} bg="fadeToBg" />}
    </div>
  );
};

const CardFall = ({cardPer100px, children, className, fadeOut, fadeIn, ...rest}) => (
  <div className={cx(className, uiStyles.position.relative)} {...rest}>
    <InnerCardFall cardPer100px={cardPer100px} fadeOut={fadeOut} fadeIn={fadeIn} />
    <div className={uiStyles.position.relative}>{children}</div>
  </div>
);

export default CardFall;
