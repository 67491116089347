import React from "react";
import {Link} from "gatsby";

const LinkOrButton = React.forwardRef(({forwardedRef, disabled, ...rest}, ref) => {
  if (rest.as) {
    const Comp = rest.as;
    return <Comp {...rest} disabled={disabled} />;
  } else if ("to" in rest) {
    const props = {
      innerRef: ref,
      ...rest,
      ...(disabled ? {onClick: e => e.preventDefault()} : {}),
    };
    return <Link {...props} />;
  } else if ("href" in rest) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a ref={ref} {...rest} />;
  } else {
    return <button ref={ref} disabled={disabled} type="button" {...rest} />;
  }
});

export default LinkOrButton;
