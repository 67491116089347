import React from "react";
import ReactDOM from "react-dom";

export default class Portal extends React.Component {
  componentDidMount() {
    this.renderPortalContents();
  }

  componentDidUpdate() {
    this.renderPortalContents();
  }

  componentWillUnmount() {
    this.tearDown();
  }

  tearDown() {
    if (!this.portal) return;
    ReactDOM.unmountComponentAtNode(this.portal);
    document.body.removeChild(this.portal);
    this.portal = null;
  }

  renderPortalContents() {
    if (this.props.children) {
      if (!this.portal) {
        this.portal = document.createElement("div");
        document.body.appendChild(this.portal);
      }
      ReactDOM.unstable_renderSubtreeIntoContainer(
        this,
        React.Children.only(this.props.children),
        this.portal
      );
    } else {
      this.tearDown();
    }
  }

  render() {
    return this.props.renderInPlace || null;
  }
}
