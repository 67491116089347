import React from "react";
import styled from "@emotion/styled";

export const IconContainer = styled("div")({
  lineHeight: 1,
  height: "1em",
  "& svg": {fill: "currentColor", height: "1em"},
});

const StrokenIconContainer = styled("div")({
  lineHeight: 1,
  height: "1em",
  "& svg": {stroke: "currentColor", height: "1em"},
});

const createIcon = (name, {usesStroke} = {}) => {
  const Comp = usesStroke ? StrokenIconContainer : IconContainer;
  return React.forwardRef((props, ref) => (
    <Comp
      ref={ref}
      {...props}
      dangerouslySetInnerHTML={{__html: require(`!!svg-inline-loader!../icons/${name}.svg`)}}
    />
  ));
};

const Icon = {
  ArrowDown: createIcon("arrow-down"),
  ArrowLeft: createIcon("arrow-left"),
  ArrowRight: createIcon("arrow-right", {usesStroke: true}),
  ArrowLongLeft: createIcon("arrow-long-left"),
  Attachment: createIcon("attachment"),
  BarChart: createIcon("bar-chart"),
  Block: createIcon("block"),
  Calendar: createIcon("calendar"),
  CaretDown: createIcon("caret-down"),
  Check: createIcon("check"),
  Comparison: createIcon("comparison"),
  Comments: createIcon("comments"),
  Close: createIcon("close"),
  Effort: createIcon("effort"),
  Facebook: createIcon("facebook"),
  Gift: createIcon("gift"),
  Globe: createIcon("globe"),
  History: createIcon("history"),
  HourglassFull: createIcon("hourglass-full"),
  HourglassHalf: createIcon("hourglass-half"),
  Instagram: createIcon("instagram"),
  Plus: createIcon("plus"),
  Play: createIcon("play"),
  PriceTag: createIcon("price-tag"),
  PriorityA: createIcon("priority-a"),
  PriorityB: createIcon("priority-b"),
  PriorityC: createIcon("priority-c"),
  Quote: createIcon("quote"),
  Review: createIcon("review"),
  Twitter: createIcon("twitter"),
  Search: createIcon("search"),
  Sort: createIcon("sort"),
  SortAsc: createIcon("sort-asc"),
  SortDesc: createIcon("sort-desc"),
  Sliders: createIcon("sliders"),
  Snoozing: createIcon("snoozing"),
  Star: createIcon("star"),
  User: createIcon("user"),
  Workflow1: createIcon("workflow-step-1"),
  Workflow2: createIcon("workflow-step-2"),
  Workflow3: createIcon("workflow-step-3"),
  Discord: createIcon("discord"),
  Google: createIcon("google"),
  Linkedin: createIcon("linkedin"),
  Youtube: createIcon("youtube"),
  Question: createIcon("question"),
};

export default Icon;
