import React, {forwardRef, useRef, useState} from "react";
import LinkOrButton from "../LinkOrButton";
import {ghostButtonStyle, primaryButtonStyle} from "./button.css";
import cx from "./cx";
import {uiStyles} from "./xui.css";

const useButtonBehaviour = ({onClick, disabled, noErrorHandling, ...rest}) => {
  const [pending, setPending] = useState(false);
  const unmountedRef = useRef();

  const handleClick = e => {
    const retVal = onClick(e);
    if (retVal && typeof retVal.then === "function") {
      setPending(true);
      retVal.then(
        () => !unmountedRef.current && setPending(false),
        error => {
          if (!noErrorHandling) console.error(error);
          if (!unmountedRef.current) setPending(false);
        }
      );
    }
  };
  return {
    disabled: pending ? true : disabled,
    onClick: onClick ? handleClick : undefined,
    ...rest,
  };
};

const bySize = {
  lg: [uiStyles.px[5], uiStyles.py[4], uiStyles.size.sm],
  sm: [uiStyles.px[3], uiStyles.py[2], uiStyles.size.sm],
  smSquare: [uiStyles.px[2], uiStyles.py[2], uiStyles.size.sm],
  xsSquare: [uiStyles.px[1], uiStyles.py[1], uiStyles.size.xs],
  lgSquare: [
    uiStyles.px[5],
    uiStyles.py[5],
    uiStyles.size.sm,
    uiStyles.md_pt[3],
    uiStyles.md_pb[3],
    uiStyles.md_pl[3],
    uiStyles.md_pr[3],
  ],
};

export const ThemedButton = forwardRef((props, ref) => {
  const {className, kind = "secondary", size = "lg", ...buttonProps} = useButtonBehaviour(props);
  return (
    <LinkOrButton
      className={cx(
        className,
        kind === "secondary" ? ghostButtonStyle : primaryButtonStyle,
        ...bySize[size]
      )}
      {...buttonProps}
      ref={ref}
    />
  );
});
