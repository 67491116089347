import React from "react";
import {classes, addClasses} from "./classes";
import xcolors from "../../lib/xcolors";

const fontFamilies = {
  default: "EbonyCdx, sans-serif",
};
const textTypes = {
  body: {
    fontFamily: fontFamilies.default,
  },
  bodyBold: {
    fontFamily: fontFamilies.default,
    fontWeight: 700,
  },
  label: {
    fontFamily: fontFamilies.default,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  semiBold: {
    fontFamily: fontFamilies.default,
    fontWeight: 500,
  },
};

const textPresets = {
  button: {
    color: "white",
    size: 3,
    type: "semiBold",
    lineHeight: "tight",
  },
  body: {
    color: "blue2",
    size: 3,
    type: "body",
    lineHeight: "default",
  },
  semiBold: {
    color: "blue2",
    size: 3,
    type: "semiBold",
    lineHeight: "tight",
  },
  bold: {
    color: "blue2",
    size: 3,
    type: "bodyBold",
    lineHeight: "tight",
  },
  label: {
    color: "active",
    size: 1,
    type: "label",
    lineHeight: "tight",
  },
};

const fontSizes = [10 / 16, 12 / 16, 14 / 16, 1, 18 / 16, 22 / 16, 32 / 16, 48 / 16];

addClasses(["color"], xcolors);
addClasses(["fontSize"], fontSizes);
addClasses(["lineHeight"], {tight: 1.1, default: 1.3125, wide: 1.75}, val => ({lineHeight: val}));
addClasses(["textAlign"], {center: "center", left: "left", right: "right"});
addClasses(["textTypes"], textTypes, val => val);

export const Text = React.forwardRef((props, ref) => {
  const preset = textPresets[props.preset] || textPresets.body;
  const {
    as: Comp = "div",
    preset: _,
    color = preset.color,
    size = preset.size,
    type = preset.type,
    lineHeight = preset.lineHeight,
    align = preset.align,
    passThrough,
    at,
    ...rest
  } = props;
  const cssList = [classes.fontSize[size], classes.textTypes[type]];
  if (lineHeight) cssList.push(classes.lineHeight[lineHeight]);
  if (align) cssList.push(classes.textAlign[align]);
  if (color) cssList.push(classes.color[color]);
  if (at) {
    Object.entries(at).forEach(([q, vals]) => {
      const atStyles = {};
      if (vals.size) atStyles.fontSize = `${fontSizes[vals.size]}rem`;
      if (vals.align) atStyles.textAlign = vals.align;
      cssList.push({[q]: atStyles});
    });
  }
  return <Comp ref={ref} css={cssList} {...rest} {...passThrough} />;
});
