import Color from "color";

const contrastCache = {};
const getContrastColor = col => {
  if (!contrastCache[col]) {
    const colObj = new Color(col);
    contrastCache[col] =
      colObj.luminosity() < 0.5
        ? "#fff"
        : colObj
            .darken(0.75)
            .rgb()
            .string();
  }
  return contrastCache[col];
};

const fadeCache = {};
const fade = (col1, fadeVal) => {
  const key = `${col1}-${fadeVal}`;
  return (fadeCache[key] =
    fadeCache[key] ||
    Color(col1)
      .alpha(fadeVal)
      .rgb()
      .string());
};

const mixCache = {};
const mix = (col1, col2, ratio = 0.5) => {
  const key = `${col1}-${col2}-${ratio}`;
  return (mixCache[key] =
    mixCache[key] ||
    Color(col1)
      .mix(Color(col2), ratio)
      .rgb()
      .string());
};

export default {
  white: "#fff",
  active: "#30A783",
  activeHover: "#35b68f",
  brand: "#7D5DA9",
  brandHover: "#8f6ebd",
  brandDim: "#47425D",
  link: "#5990A6",
  linkHover: "#6DAFC5",
  blue: "#0076FF",
  green: "#00FFB2",
  accent: "#e50f3c",
  accentHover: "#f2214f",

  danger: "#ff205e",
  dangerPale: "#fff0f4",
  dangerTextOnDark: "#ffb5c8",
  focus: "rgb(59, 153, 252)",
  success: "#129b54",

  black: "#000",
  dark: "#343542",
  darkHover: "#2a2b32",
  quiteDark: "#444654",
  darkish: "#626366",
  brightBg: "#F7F7F7",
  brightishBg: "#efefef",
  borderGray: "#CBCBCB",
  midGray: "#9B9B9B",

  snoozing: "#7d1274",
  blocked: "#c01b1b",
  assigned: "#a8925d",
  unassigned: "#828282",
  review: "#159590",
  done: "#31d367",
  started: "#2569ad",
  archived: "#291600",
  deleted: "#43282a",

  snoozingFont: "#ba81b5",
  blockedFont: "#801212",
  assignedFont: "#70613e",
  unassignedFont: "#575757",
  reviewFont: "#0e6360",
  doneFont: "#218d45",
  startedFont: "#194673",
  archivedFont: "#8d8377",

  snoozingLight: "#d4b0d1",
  blockedLight: "#eab3b3",
  assignedLight: "#e2dbc9",
  unassignedLight: "#d5d5d5",
  reviewLight: "#b1dcda",
  doneLight: "#baf0cc",
  startedLight: "#b6cde4",
  archivedLight: "#b8b1aa",

  lightBg: "#e4e5e7",
  getContrastColor,
  fade,
  mix,
};
