import React, {forwardRef, useState} from "react";
import {Link} from "gatsby";
import styled from "@emotion/styled";
import colors from "../lib/colors";
import xcolors from "../lib/xcolors";
import {errorToString} from "../lib/utils";

export const rawButtonStyle = ({disabled}) => ({
  fontFamily: "inherit",
  fontSize: "inherit",
  textDecoration: "none",
  padding: 0,
  border: "none",
  lineHeight: "normal",
  background: "none",
  transitionProperty: "background-color, color, box-shadow, border-color, opacity",
  ...(disabled ? {opacity: 0.5} : {cursor: "pointer"}),
});

export const sharedStyles = [
  rawButtonStyle,
  {
    textAlign: "center",
    width: "auto",
    lineHeight: 1,
    display: "inline-block",
    textDecoration: "none",
    letterSpacing: "0.04rem",
    fontWeight: "bold",
    color: colors.white,
  },
];

const ErrorTileContainer = styled("div")({
  position: "absolute",
  top: "100%",
  left: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  minWidth: "10rem",
});

const ErrorTile = styled("div")({
  marginTop: "0.25rem",
  backgroundColor: xcolors.error100,
  color: xcolors.error600,
  border: `1px solid ${xcolors.error300}`,
  padding: "0.3rem 1rem",
  fontWeight: "bold",
  fontSize: "0.8rem",
  borderRadius: "0.3rem",
  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
});

const Button = forwardRef(({onClick, ...props}, ref) => {
  const [error, setError] = useState();

  const handleClick = e => {
    setError(false);
    if (onClick) {
      const p = onClick(e);
      if (p && typeof p.catch === "function") {
        p.catch(err => {
          console.error(err);
          setError(errorToString(err));
        });
      }
    }
  };

  return (
    <div style={{position: "relative", flex: "none"}}>
      <button type="button" ref={ref} onClick={handleClick} {...props} />
      {error && (
        <ErrorTileContainer>
          <ErrorTile>{error}</ErrorTile>
        </ErrorTileContainer>
      )}
    </div>
  );
});

const LinkOrButton = React.forwardRef((props, ref) =>
  props.href ? (
    // eslint-disable-next-line
    <a ref={ref} {...props} />
  ) : props.to ? (
    <Link ref={ref} {...props} />
  ) : (
    <Button ref={ref} {...props} />
  )
);

const tStyleBySize = {small: {fontSize: "0.8rem"}};

const tStyleByColor = {
  dim: {
    color: xcolors.gray500,
    ":hover": {color: xcolors.gray200, backgroundColor: colors.fade(colors.black, 0.1)},
  },
};

export const TransparentButton = styled(LinkOrButton, {
  shouldForwardProp: prop =>
    ["active", "size", "isOnLight", "shadowSize", "color"].indexOf(prop) === -1,
})(sharedStyles, ({size, color}) => ({
  margin: -5,
  padding: 5,
  borderRadius: 3,
  backgroundColor: "transparent",
  ...tStyleBySize[size],
  ":hover": {
    backgroundColor: colors.fade(colors.black, 0.1),
  },
  ...tStyleByColor[color],
  ":focus": {
    outline: "none",
    boxShadow: `0 0 0 2px rgba(0,0,0,0.15)`,
  },
}));

const styleBySize = {
  small: {padding: "0.5rem 1rem 0.4rem", fontSize: "0.6rem"},
  default: {padding: "0.7rem 1.2rem", fontSize: "0.7rem"},
  big: {padding: "1.2rem 2.5rem", fontSize: "1rem"},
};

export const PlainButton = styled(LinkOrButton, {
  shouldForwardProp: prop => ["active", "size", "isOnLight", "shadowSize"].indexOf(prop) === -1,
})(
  sharedStyles,
  ({isOnLight, shadowSize = 1, disabled}) => ({
    borderRadius: "2rem",
    border: "none",
    backgroundColor: colors.brand,
    boxShadow: [
      `0 0 0 0.2rem transparent`,
      "0 0.1rem 0.2rem rgba(0,0,0,0.1), 0 0.2rem 1rem rgba(0,0,0,0.2)",
    ].join(","),
    ":hover": !disabled && {
      backgroundColor: colors.brandHover,
    },
    ":focus": {
      outline: "none",
      boxShadow: [
        `0 0 0 0.2rem ${colors.fade(colors.brand, 0.6)}`,
        "0 0.1rem 0.2rem rgba(0,0,0,0.1), 0 0.2rem 1rem rgba(0,0,0,0.2)",
      ].join(","),
    },
  }),
  ({size = "default"}) => styleBySize[size]
);
