import {css} from "@emotion/core";
import xcolors from "../../lib/xcolors";

export const classes = {};

export const addClasses = (
  types,
  steps,
  stepToRule = (step, type) => ({[type]: typeof step === "string" ? step : `${step}rem`})
) => {
  const isArr = Array.isArray(steps);
  types.forEach(type => {
    if (isArr) {
      classes[type] = [];
      steps.forEach(step => {
        classes[type].push(css(stepToRule(step, type)));
      });
    } else {
      classes[type] = {};
      Object.entries(steps).forEach(([key, val]) => {
        classes[type][key] = css(stepToRule(val, type));
      });
    }
  });
};

export const containerPropsToCss = props => {
  const {
    css: cssList = [],
    pa,
    px = pa,
    py = pa,
    pt = py,
    pl = px,
    pr = px,
    pb = py,
    bg,
    elevation,
    fillParent,
    relative,
    absolute,
    fixed,
    inset,
    sticky,
    rounded,
    border,
    noOverflow,
    as: _,
    ...rest
  } = props;
  if (pt !== null && pt !== undefined) cssList.push(classes.paddingTop[pt]);
  if (pb !== null && pb !== undefined) cssList.push(classes.paddingBottom[pb]);
  if (pl !== null && pl !== undefined) cssList.push(classes.paddingLeft[pl]);
  if (pr !== null && pr !== undefined) cssList.push(classes.paddingRight[pr]);
  if (bg) cssList.push(classes.backgroundColor[bg]);
  if (elevation) cssList.push(classes.boxShadow[elevation]);
  if (fillParent) cssList.push(classes.flex.auto);
  if (relative) cssList.push(classes.position.relative);
  if (fixed) cssList.push(classes.position.fixed);
  if (sticky) {
    cssList.push(classes.position.sticky);
    cssList.push(css({top: 0, zIndex: 1}));
  }
  if (absolute) cssList.push(classes.position.absolute);
  if (noOverflow) cssList.push(classes.overflow.none);
  if (inset) cssList.push(classes.inset[inset]);
  if (rounded) cssList.push(classes.borderRadius[rounded]);
  if (border) cssList.push(borderClass, classes.borderColor[border]);

  return {css: cssList, ...rest};
};

const borderClass = css({borderStyle: "solid", borderWidth: 1});

export const spacingSteps = [0.25, 0.5, 0.75, 1, 1.5, 2, 3, 4, 6];

addClasses(["paddingTop", "paddingLeft", "paddingRight", "paddingBottom"], spacingSteps);
addClasses(["backgroundColor"], xcolors);
addClasses(["alignItems"], {
  start: "flex-start",
  center: "center",
  end: "flex-end",
  baseline: "baseline",
});
addClasses(["justifyContent"], {start: "flex-start", center: "center", end: "flex-end"});
addClasses(["boxShadow"], {
  1: "0 0.1rem 0.1rem rgba(0,0,0,0.1), 0 0.1rem 0.5rem rgba(0,0,0,0.05)",
  2: "0 0.1rem 0.2rem rgba(0,0,0,0.1), 0 0.2rem 1rem rgba(0,0,0,0.2)",
});
addClasses(["flex"], {auto: "auto"});
addClasses(["overflow"], {none: "hidden"});
addClasses(["position"], {
  relative: "relative",
  absolute: "absolute",
  fixed: "fixed",
  sticky: "sticky",
});
addClasses(["borderRadius"], {
  xs: "0.15rem",
  sm: "0.2rem",
  md: "0.3rem",
  lg: "0.5rem",
});
addClasses(["borderColor"], xcolors);
addClasses(["inset"], {full: "full", x: "x", y: "y"}, val => {
  const vals = {};
  if (val !== "x") {
    vals.top = 0;
    vals.bottom = 0;
  }
  if (val !== "y") {
    vals.left = 0;
    vals.right = 0;
  }
  return vals;
});
