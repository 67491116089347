import React, {createContext, useContext, useEffect, useState} from "react";
import ThemeBox from "./bricks/ThemeBox";
import {headerThemeEvents, HeaderThemeSentinel} from "./Header";
import {Box} from "./xui/Box";

const ThemeCtx = createContext();

const Container = ({initial, children, ...props}) => {
  const [val, setVal] = useState(initial);

  return (
    <ThemeCtx.Provider value={setVal}>
      <Box className={val} bg="bgBack" {...props} relative>
        {children}
        <HeaderThemeSentinel theme={val} />
      </Box>
    </ThemeCtx.Provider>
  );
};

export const createObserver = ({node, onIntersect, position = "top", threshold = 1}) => {
  if (typeof IntersectionObserver === "undefined") return;
  const handleIntersect = ([event]) => {
    if (event.isIntersecting) {
      if (position === "bottom") {
        if (event.intersectionRect.top > event.rootBounds.height * 0.25) {
          return;
        }
      }
      if (position === "top") {
        if (event.intersectionRect.bottom < event.rootBounds.height * 0.25) {
          return;
        }
      }
      onIntersect();
    }
  };
  const options = {root: null, threshold};
  const observer = new IntersectionObserver(handleIntersect, options);
  observer.observe(node);
  return () => {
    observer.disconnect();
  };
};

const Sentinel = ({theme}) => {
  const [nodeTop, setNodeTop] = useState();
  const [nodeBottom, setNodeBottom] = useState();
  const setVal = useContext(ThemeCtx);

  useEffect(() => {
    if (nodeTop && nodeBottom) {
      const handleIntersect = () => {
        setVal(theme);
        headerThemeEvents.emit(theme);
      };
      const unsubs = [
        createObserver({node: nodeTop, onIntersect: handleIntersect, position: "top"}),
        createObserver({node: nodeBottom, onIntersect: handleIntersect, position: "bottom"}),
      ];
      return () => unsubs.forEach(fn => fn());
    }
  }, [nodeTop, nodeBottom, theme, setVal]);

  return (
    <>
      <Box
        height="50vh"
        absolute
        top="0"
        left="0"
        right="0"
        pointerEvents="none"
        ref={setNodeTop}
      />
      <Box
        height="50vh"
        absolute
        bottom="0"
        left="0"
        right="0"
        pointerEvents="none"
        ref={setNodeBottom}
      />
    </>
  );
};

const ScollerThemeBox = ({theme, ...props}) => (
  <ThemeBox outerChild={theme && <Sentinel theme={theme} />} {...props} />
);

const ColorScroller = {Container, ThemeBox: ScollerThemeBox};

export default ColorScroller;
