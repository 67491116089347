import React from "react";
import {css} from "@emotion/core";
import xcolors from "../lib/xcolors";
import {FieldWithLabel} from "../lib/forms/FieldWithLabel";

const styles = {
  input: {
    default: css({
      width: "20em",
      maxWidth: "100%",
      background: "transparent",
      fontFamily: "inherit",
      lineHeight: 1.2,
      display: "block",
      textDecoration: "none",
      border: "none",
      borderRadius: 9999,
      ":focus": {outline: "none"},
      "::placeholder": {color: xcolors.purple500},
    }),
    bySize: {
      sm: css({
        fontSize: "0.9rem",
        padding: "0.1rem 0.8rem 0.1rem",
      }),
      md: css({
        fontSize: "1rem",
        padding: "0.1rem 0.8rem 0.1rem",
      }),
      lg: css({
        fontSize: "1.2rem",
        padding: "0.8rem 1rem 0.6rem",
      }),
    },
  },
};

export const Input = React.forwardRef(({size = "lg", onChange, ...rest}, ref) => (
  <input
    css={[styles.input.default, styles.input.bySize[size]]}
    ref={ref}
    onChange={e => onChange(e.target.value)}
    {...rest}
  />
));

export const InputWithLabel = React.forwardRef((props, ref) => (
  <FieldWithLabel as={Input} ref={ref} {...props} />
));
