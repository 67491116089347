import React from "react";
import css from "@emotion/css";
import {classes, containerPropsToCss, addClasses, spacingSteps} from "./classes";
import {pushClass} from "./Push";

addClasses(["horSpacing"], spacingSteps, val => ({
  [`> *:not(:last-child):not(.css-${pushClass.name})`]: {marginBottom: `${val}rem`},
}));

const colClass = css({display: "flex", flexDirection: "column"});
const colCss = props => {
  const {css: passedCss = [], sp, align, justify, ...rest} = props;
  const cssList = passedCss ? [passedCss] : [];
  cssList.push(colClass);
  if (sp !== undefined && sp !== null) cssList.push(classes.horSpacing[sp]);
  if (align) cssList.push(classes.alignItems[align]);
  if (justify) cssList.push(classes.justifyContent[justify]);
  return {css: cssList, ...rest};
};

const Col = React.forwardRef((props, ref) => {
  const Comp = props.as || "div";
  return <Comp ref={ref} {...containerPropsToCss(colCss(props))} />;
});

export default Col;
